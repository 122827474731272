$(document).on("click", ".link_to_page", (e) => {
	let el = e.currentTarget;
	let title = $(el).data("title")
	let is_public = $(el).data("is_public")
	let is_link = $(el).data("is_link")

	if(is_link) {
		frappe.set_route("List", title)
		return;
	}

	let url = "/app/" 
	url += is_public ? frappe.router.slug(title): "private/" + frappe.router.slug(title)

	frappe.set_route(url)
})

$(document).on("click", ".side-menu__link", function(e) {
	const $this = $(e.currentTarget)

	if ($($this).parent().find("ul").length) {
	  if ($($this).parent().find("ul").first()[0].offsetParent !== null) {
		$($this).find(".side-menu__link__chevron").removeClass("transform rotate-180");
		$($this).removeClass("side-menu__link--active-dropdown");
		$($this).parent().find("ul").first().slideUp(300, function() {
		  $($this).removeClass("side-menu__link__sub-open");
		});
	  } else {
		$($this).find(".side-menu__link__chevron").addClass("transform rotate-180");
		$($this).addClass("side-menu__link--active-dropdown");
		$($this).parent().find("ul").first().slideDown(300, function() {
		  $($this).addClass("side-menu__link__sub-open");
		});
	  }
	}
  });